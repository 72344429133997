<template>
	<article class="layer" style="display:block">
		<div class="layer__dim"></div>
		<div class="layer__wrap">
			<div class="layer__inner">
				<header class="layer__header">
					<h3 class="layer__title">
						{{t('10742')}} (<span>최대 {{hour}}시간</span>)<!-- 재번역체크 -->
					</h3>
					<button type="button" class="btn-close" @click="handleClose">
						<i class="icon icon-close--light"></i>
					</button>
				</header>
				<div class="layer__body">
					<div class="layer__box">
						<ul class="layer__list">
							<li v-for="time in times" :key="time.text" @click="handleSelect(time.value)"><a :class="{active: time.value === sdayuse}">{{time.text}}</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</article>
</template>

<script>
import { computed } from '@vue/reactivity';
import dayjs from 'dayjs';
import { onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n' //번역필수 모듈

export default {
	props: ["handleClose", "room", "handleSelect", "sdayuse", "sdate"],
	setup(props) {

		const { t }= useI18n() //번역필수 모듈
		const stime = computed(() => {
			if (dayjs(props.sdate).diff(dayjs().startOf('day'), "day") === 0) {
				const nowHour = dayjs().hour();
				const startHour = props.room ? Number(props.room.dayuse_stime.split(":")[0]) : nowHour;
				return startHour > nowHour ? startHour : nowHour + 1;
			} else {
				return props.room ? Number(props.room.dayuse_stime.split(":")[0]) : 11;
			}
		});
		const etime = computed(() => {
			return props.room ? Number(props.room.dayuse_etime.split(":")[0]) : 23;
		});
		const hour = computed(() => {
			return props.room ? props.room.dayuse_hours : 5;
		});

		onBeforeRouteLeave(() => {
			props.handleClose();
			return false;
		})

		return {
			times: computed(() => {
				const times = []; 
				for(let i = stime.value; i <= etime.value - hour.value; i++) {
					times.push({
						text: `${String(i).padStart(2, "0")}:00 ~ ${String(i + hour.value).padStart(2, "0")}:00`,
						value: `${String(i).padStart(2, "0")}:00`
					});
				}
				return times;
			}),
			t,  //번역필수 모듈
			hour
		}
	},
}
</script>